var windowHeight;
var windowWidth;
var objectLead;
var scrollAlertOffsets = Array();
var scrollAlertObjects = Array();

jQuery(window).on("load", function(){
	getWindowDimensions();
	scrollAlertSetup();
	setupResizeEvents();
	setupAnimationEvents();
	
});

function setupAnimationEvents(){
	requestAnimationFrame(scrollAlert);
}

function setupResizeEvents(){
		
	jQuery(window).resize(function(){
		
		getWindowDimensions();
		
	});
	
}

function scrollAlertSetup(){

	scrollAlertOffsets = Array();
	scrollAlertObjects = Array();
	
	jQuery(".scroll-alert").each(function(){
		
		var scrollObject = jQuery(this);
		
		scrollAlertObjects.push(scrollObject);
		scrollAlertOffsets.push(scrollObject.offset().top);		

	});
	
	scrollAlert();
	
}

function scrollAlert(){
	
	var windowOffset = jQuery(window).scrollTop();

	for(var i = 0; i < scrollAlertOffsets.length; i++){
	
		if(windowOffset + windowHeight > scrollAlertOffsets[i] + objectLead)
			scrollAlertObjects[i].addClass("scroll-active");
		
	}
	
	requestAnimationFrame(scrollAlert);
	
}

function getWindowDimensions(){
		
	windowHeight = jQuery(window).height();
	windowWidth = jQuery(window).width();
	objectLead = 100;
	
}